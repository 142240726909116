import Form, { formCreate } from '@/found/components/form';
import request from '@/found/utils/request';
// import ProductList from './product-list.vue';

// formCreate.component('ProductList', ProductList);
export default {
  extends: Form,
  components: {
    formCreate: formCreate.$form(),
  },
  data() {
    return {
      rule: [],
      formFunctionCode: 'bank_count_form',
    };
  },

  methods: {
    setRule(item) {
      const v = item;
      if (v.field === 'companyCode') {
        v.restful = '/mdm/companyInfo/companyPageList';
        v.restfulParams = { pageSize: 1000 };
        v.props = {
          ...v.props,
          remoteParams: 'companyName',
          remote: true,
          filterable: true,
        };
        v.optionsKey = {
          label: 'companyName',
          value: 'companyCode',
        };
        v.on = {
          change: (e) => {
            this.setValue({
              companyCode: e,
            });
          },
          getLabel: (e) => {
            this.setValue({
              companyName: e,
            });
          },
        };
      }
      return item;
    },

    formComplete() {
      const userInfo = JSON.parse(sessionStorage.getItem('userInfo'));
      const { row, type, id } = this.formConfig;
      if (type === 'add') {
        this.setValue({ linkUser: userInfo.fullName });
      }
      if (type === 'edit' || type === 'view') {
        this.setValue(row);
      }
    },

    // 提交
    submit() {
      const formData = this.getFormData(); // 获取表单的值
      if (formData) {
        const url = '/mdm/bankInfo/edit';
        // 有id  就是编辑
        if (this.formConfig.id) {
          formData.id = this.formConfig.id;
        }
        request.post(url, formData).then((res) => {
          if (res.success) {
            this.$message({
              type: 'success',
              message: '操作成功',
            });
            this.$emit('onClose');
            this.$emit('onGetList');
          }
        });
      }
    },
  },
};
